import axios from "axios";
import Vue from "vue";
import { Toast,Dialog} from "vant";
import router from '@/router/index';
// 导入store
import store from '@/store/index';
Vue.use(Toast,Dialog);

const service = axios.create({
  baseURL: "http://huruqing.cn:3003",
  timeout: 50000, // 请求超时时间(因为需要调试后台,所以设置得比较大)
});

// request 对请求进行拦截
service.interceptors.request.use(
  (config) => {
    //获取token
    let token = store.state.token;
    // 开启loading
    // Toast.loading({
    //   message: "加载中...",
    //   forbidClick: true,
    //   loadingType: "spinner",
    // });
    // 请求头添加token
    config.headers["user-token"] = token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response 响应拦截器
service.interceptors.response.use(
  (response) => {
    Toast.clear();
    const res = response.data;
    if (res.code == 666) {
      return res;
    }else if(res.code == 603){
      Dialog
          .confirm({
            title: "温馨提示",
            message: "您还没登录,请先登录?",
          })
          .then(() => { 
            router.push('/account/login');
          }).catch(() => {});
    } else {
      // 成功连接到后台, 但是没有返回正确的数据
      Toast.fail(res.msg);
    }
  },
  (error) => {
    console.log(error);
    Toast.clear();
    // 跟后台连接失败
    Toast.fail("网络异常,请稍后再试");
  }
);

export default service;